.container {
    background-color: #fff !important;
    padding: 0 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0px 11px 12px -16px rgb(0 0 0 / 75%);
}

.containerFixed{
    background-color: #fff !important;
    padding: 0 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0px 11px 12px -16px rgb(0 0 0 / 75%);
    position: fixed;
    width: 100%;
    top: 0;
}

.darkTopNav {
    color: #fff;
    height: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0px;
    background-color: #444;
}

.navTop {
    background-color: #fff !important;
    padding: .2rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0px 11px 12px -16px rgb(0 0 0 / 75%);
}

.navBtn {
    color: #1E3C70;
    border: none;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    background-color: rgba(30, 60, 112, 0.1);
}

.navBtn2 {
    color: #1E3C70;
    font-size: 1rem;
    font-weight: 600;
}

.navIcon {
    font-size: 1.1rem !important;
}

.navLink {
    color: #777;
    text-decoration: none;
    font-size: .9rem !important;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    padding-right: 1rem;
}

.flex {
    display: flex;
}

.navLink:hover {
    color: black;
}

.navBtn2 {
    right: 0;
}

.jsB {
    justify-content: space-between;
}

@media only screen and (max-width: 576px) {
    .flex {
        display: block;
        text-align: center;
    }
}