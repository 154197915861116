.container {
    border-top: 3px solid #f4f6ff;
    padding-top: 20px;
    padding-bottom: 5px;
    background-color: white;
}

.grid {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.heading {
    font-size: 0.9rem;
    font-weight: 900;
    line-height: 17px;
    margin-bottom: 10px;
}

.ul {
    margin-top: 5px;
    padding-inline-start: 0;
}

.li {
    list-style-type: none;
}

.link {
    color: #222;
    padding: 0;
    text-align: start;
    font-family: "Source Sans Pro", "Roboto", sans-serif;
    font-size: .9rem;
    white-space: break-spaces !important;
    font-weight: 400;
    text-decoration: none;
    margin-bottom: 10px;
    display: block;
    font-size: 13px;
}

.socialLinks {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    margin-bottom: 1rem;
    /* justify-content: center; */
}

.iconWrapper {
    background-color: black;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 5px;
    display: flex;
    justify-content: center;
    padding-top: 1px;
    /* align-items: center; */
}

.footerSec2 {
    color: #707070;
    border-top: 1px solid black;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 50px;
}

.footerSec2Grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerSocialGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link2 {
    color: rgb(97, 160, 175);
    margin: 5px 0px;
    font-weight: 600;
    text-decoration: none;
}

.link2:hover {
    color: rgb(97, 160, 175);
}

.link3 {
    color: #707070;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
}

.link3:hover {
    color: #707070;
}

.link3Wrapper {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 576px) {
    .grid {
        grid-template-columns: 50% 50%;
    }

    .footerSec2Grid {
        display: block !important;
        text-align: center !important;
        padding-bottom: 1rem;
    }

    .footerSocialGrid {
        display: block !important;
        text-align: center !important;
    }

    .footerSocialGrid div {
        justify-content: center;
        margin-right: 0;
    }
}